import React, { useState, useRef, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { ButtonContext } from "../context/button-context"
import InView from "../in-view"

import HeroFlowmap from "../hero-flowmap"

export const fragmentQl = graphql`
  fragment WorkpageHeroFragment on WordPress_Page_Workpagefields {
    hero {
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
        }
      }
      imageMobile {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      title
      text
    }
  }
`

const WorkHero = ({ hero }) => {
  const triggerRef = useRef()
  const [isDesktop, setIsDesktop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1000,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const textAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1100,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      <InView
        element={triggerRef.current}
        setToggle={setButtonTriggers}
        location="hero"
      >
        <TriggerButton ref={triggerRef} />
      </InView>
      <WrapHero>
        <HeroFlowmap
          mobile={hero.imageMobile.imageFile.childImageSharp.fluid}
          desktop={hero.image.imageFile.publicURL}
        />
        <WrapText>
          <animated.h1 style={titleAnimation}>{hero.title}</animated.h1>
          <animated.p style={textAnimation}>{hero.text}</animated.p>
        </WrapText>
      </WrapHero>
    </Outer>
  )
}

export default WorkHero

const Outer = styled.section`
  position: relative;
  min-height: 100vh;
  height: auto;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: auto;
    min-height: 100vh;
  }

  @media (max-width: 650px) {
    min-height: 62vh;
  }
`

const TriggerButton = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  pointer-events: none;
`

const WrapHero = styled.div`
  position: relative;
  height: 100%;
`

const WrapText = styled.div`
  position: absolute;
  width: calc(45% - 15rem);
  height: auto;
  top: 50%;
  right: 17rem;
  transform: translateY(-50%);

  @media (max-width: 1024px) {
    position: relative;
    top: 0;
    left: 0;
    /* width: 80%; */
    width: 66%;
    margin: 5rem auto;
    transform: translateY(0);
    text-align: center;
  }

  @media (max-width: 650px) {
    display: none;
  }

  h1 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 5rem;
    line-height: 1.15;
    letter-spacing: -0.075rem;
    margin-bottom: 3rem;
    max-width: 16ch;

    @media (max-width: 1024px) {
      font-size: 5rem;
      line-height: 1.25;
      margin-left: auto;
      margin-right: auto;
    }
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.75;
    max-width: 52ch;

    @media (max-width: 1024px) {
      font-size: 2rem;
      margin: 0 auto;
    }

    @media (max-width: 650px) {
      font-size: 2.25rem;
      line-height: 1.8;
      max-width: 100%;
    }
  }
`
