import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { useSpring, useTrail, animated } from 'react-spring'
import Observer from '../observer'
import MaskedImage from '../masked-image'
import MaskedVideo from '../masked-video'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'
import InView from "../in-view"

import Triangle from '../../images/filter_triangle.svg'

export const fragment = graphql`
  fragment WorkListSelectedFragment on WordPress_Page_Workpagefields {
    workList {
      caseStudies {
        ... on WordPress_Project {
          workFields {
            featuredInfos {
              subtitle
              title
              image {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imagePortrait {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              videoSquare {
                mediaItemUrl
              }
              videoPortrait {
                mediaItemUrl
              }
              videoImageFallback {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageOrVideo
            }
          }
          slug
          categories {
            nodes {
              name
            }
          }
          projectFilters {
            edges {
              node {
                name
              }
            }
          }
          industryFilters {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`

const WorkList = ({ projects, industryFilters, projectFilters }) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedProjectFilters, setSelectedProjectFilters] = useState([]);
  const [selectedIndustryFilters, setSelectedIndustryFilters] = useState([]);
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [on, toggle] = useState(false)
  const [filtersFade, filtersFadeToggle] = useState(false)
  const [industryToggle, setIndustryToggle] = useState(false)
  const [fadeToggle, setFadeToggle] = useState(false)
  const [animatedContent, setAnimatedContent] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const ref = useRef()
  const filtersRef = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const handleProjectFilterSelection = (filter) => {
    setFadeToggle(true)
    setTimeout(() => {
      if (filter === "All") {
        setSelectedProjectFilters([]);
        setSelectedIndustryFilters([]);
      } else if (selectedProjectFilters.includes(filter)) {
        setSelectedProjectFilters(selectedProjectFilters.filter((f) => f !== filter));
      } else {
        setSelectedProjectFilters([filter]);
      }
      setFadeToggle(false)
    }, 500)
  };
  
  const handleIndustryFilterSelection = (filter) => {
    setFadeToggle(true);
    setTimeout(() => {
      if (filter === "All") {
        setSelectedIndustryFilters([]);
      } else if (selectedIndustryFilters.includes(filter)) {
        setSelectedIndustryFilters(
          selectedIndustryFilters.filter((f) => f !== filter)
        );

        // If no more filters are left, set the intrdustry filters to "All"
        if (selectedIndustryFilters.length === 1) {
          setSelectedIndustryFilters([]);
        }
      } else {
        setSelectedIndustryFilters([...selectedIndustryFilters, filter]);
      }
      setFadeToggle(false);
    }, 500);
  };

  const handleClearFilters = () => {
    setFadeToggle(true)
    setTimeout(() => {
      setSelectedProjectFilters([]);
      setSelectedIndustryFilters([]);
      setFadeToggle(false)
    }, 500)
  };

  const filteredProjects = projects.filter((project) => {
    let projectFilterMatch = true;
    let industryFilterMatch = true;
    
    if (selectedProjectFilters.length !== 0) {
      projectFilterMatch = selectedProjectFilters.some((filter) => project.projectFilters.edges.some(({ node }) => node.name.includes(filter)));
    }
    
    if (selectedIndustryFilters.length !== 0 && projectFilterMatch) {
      industryFilterMatch = selectedIndustryFilters.some((filter) => project.industryFilters.edges.some(({ node }) => node.name.includes(filter)));
    }
    
    return projectFilterMatch && industryFilterMatch;
  });

  const isIndustryFilterApplicable = (industryFilter) => {
    if (selectedProjectFilters.length > 0) {
      // Check which industry filters apply to the full list of projects (projects variable) based on the currently selected project filter
      const applicableIndustryFilters = projects
        .filter((project) => selectedProjectFilters.some((filter) => project.projectFilters.edges.some(({ node }) => node.name === filter)))
        .map((project) => project.industryFilters.edges.map(({ node }) => node.name))
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index);
      return applicableIndustryFilters.includes(industryFilter);
      
    } else {
      return true
    }
  };

  const isProjectFilterApplicable = (projectFilter) => {
    return filteredProjects.some((project) => project.projectFilters.edges.some(({ node }) => node.name === projectFilter));
  };

  const filterToggleAnimation = useSpring({
    overflow: 'hidden',
    maxHeight: on ? '600px' : '0px',
  })

  const toggleIndustryAnimation = useSpring({
    overflow: 'hidden',
    maxHeight: industryToggle ? '500px' : '0px'
  })

  const animationTriangle = useSpring({
    transform: industryToggle ? 'rotate(180deg)' : 'rotate(0deg)'
  })

  const fade = useSpring({
    opacity: fadeToggle ? 0 : 1,
    from: { opacity: 0 },
    config: { duration: fadeToggle ? 300 : 0 }
  })

  const filtersFadeAnimation = useSpring({
    opacity: filtersFade ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: filtersFade ? 300 : 0 }
  })

  const filtersAnimation = useTrail(projectFilters.length, {
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 8,
      tension: 2500,
      friction: 400
    },
    delay: animatedContent ? 100 : 50
  })
  
  const industryFiltersAnimation = useTrail(industryFilters.length, {
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 8,
      tension: 2500,
      friction: 400
    },
    delay: animatedContent ? 100 : 50
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={animatedContent} setToggle={setAnimatedContent}>
        <WorkBlock ref={ref}>
          <Observer
            element={filtersRef.current}
            toggle={filtersFade}
            setToggle={filtersFadeToggle}
            ratio={0.25}
          />
          <WrapFilters style={filtersFadeAnimation}>
            <FilterButton onClick={() => toggle(!on)}>
              Filters
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.14 25.98">
                <path d="m.93,4.63h.13c.5,1.98,2.46,3.17,4.39,2.66,1.27-.34,2.26-1.35,2.59-2.66h18.22c.5-.02.89-.45.87-.96-.02-.49-.4-.88-.87-.9H8.04C7.55.8,5.58-.39,3.65.12,2.38.45,1.39,1.47,1.06,2.78h-.13C.44,2.76.02,3.16,0,3.67c-.02.51.38.94.87.96.02,0,.04,0,.06,0Zm3.62-2.79c1,0,1.81.83,1.81,1.86s-.81,1.86-1.81,1.86-1.81-.83-1.81-1.86c0-1.03.81-1.86,1.81-1.86,0,0,0,0,0,0Zm21.71,10.21h-.13c-.5-1.98-2.46-3.17-4.39-2.66-1.27.34-2.26,1.35-2.59,2.66H.93c-.5-.02-.92.39-.93.9s.38.94.87.96c.02,0,.04,0,.06,0h18.22c.5,1.98,2.46,3.17,4.39,2.66,1.27-.34,2.26-1.35,2.59-2.66h.13c.5-.02.89-.45.87-.96-.02-.49-.4-.88-.87-.9h0Zm-3.62,2.79c-1,0-1.81-.83-1.81-1.86,0-1.03.81-1.86,1.81-1.86,1,0,1.81.83,1.81,1.86,0,1.03-.81,1.86-1.81,1.86h0Zm3.62,6.5h-9.18c-.5-1.98-2.46-3.17-4.39-2.66-1.27.34-2.26,1.35-2.59,2.66H.93c-.5.02-.89.45-.87.96.02.49.4.88.87.9h9.17c.5,1.98,2.46,3.17,4.39,2.66,1.27-.34,2.26-1.35,2.59-2.66h9.17c.5-.02.89-.45.87-.96-.02-.49-.4-.88-.87-.9h0Zm-12.66,2.79c-1,0-1.81-.83-1.81-1.86s.81-1.86,1.81-1.86,1.81.83,1.81,1.86c0,1.03-.81,1.86-1.81,1.86h0,0Z" style={{fill: 'currentColor'}}/>
              </svg>
            </FilterButton>

            <FilterInner style={filterToggleAnimation}>
              <FilterGroup>
                <IndividualFilter
                  type="button"
                  value="all"
                  className={selectedProjectFilters.length === 0 ? 'active' : ''}
                  onClick={() => {handleProjectFilterSelection('All')}}
                >
                  <span>All Work</span>
                </IndividualFilter>

                {filtersAnimation.map(({ x, ...rest }, index) => {
                  const filter = projectFilters[index]
                  return (
                    <IndividualFilter
                      key={filter.node.name}
                      type="button"
                      value={filter.node.name}
                      onClick={e => {
                        handleProjectFilterSelection(e.target.value)
                      }}
                      className={selectedProjectFilters.includes(filter.node.name) ? 'active' : ''}
                      disabled={!isProjectFilterApplicable(filter.node.name)}
                    >
                      {filter.node.name}
                    </IndividualFilter>
                  )
                })}
                <Divider />
              </FilterGroup>
              <FilterGroupIndustry>
                <FilterGroupHeading onClick={() => setIndustryToggle(!industryToggle)}>
                  Filter by Industry :
                  <animated.span style={animationTriangle}>
                    <Triangle />
                  </animated.span>
                </FilterGroupHeading> 
                <IndustryToggleArea style={toggleIndustryAnimation}>
                  <IndividualFilter
                    type="button"
                    value="all"
                    className={selectedIndustryFilters.length === 0 ? 'active' : ''}
                    onClick={() => {handleIndustryFilterSelection('All')}}
                  >
                    <span>All Industries</span>
                  </IndividualFilter>

                  {industryFiltersAnimation.map(({ x, ...rest }, index) => {
                    const filter = industryFilters[index]
                    return (
                      <IndividualFilter
                        key={filter.node.name}
                        type="button"
                        value={filter.node.name}
                        onClick={e => {
                          handleIndustryFilterSelection(e.target.value)
                        }}
                        className={selectedIndustryFilters.includes(filter.node.name) ? 'active' : ''}
                        disabled={!isIndustryFilterApplicable(filter.node.name)}
                      >
                        {filter.node.name}
                      </IndividualFilter>
                    )
                  })}
                </IndustryToggleArea>
                <Divider />
              </FilterGroupIndustry>
              <ButtonGroup>
                <ClearButton onClick={() => handleClearFilters()}>Clear</ClearButton>
                <CloseButton onClick={() => toggle(false)}>Close</CloseButton>
              </ButtonGroup>
            </FilterInner>
          </WrapFilters>

          <WorkFeatured style={fade}>
            {filteredProjects.map((project, i) => (
              <WorkItem
                key={project.workFields.featuredInfos.title + i}
                to={`/work/${project.slug}`}
                onClick={event => {
                  event.preventDefault()
                  setTransitionActive(true)
                  setTimeout(() => {
                    navigate(`/work/${project.slug}`)
                    setTransitionActive(false)
                  }, 500)
                }}
              >
                <WrapItem ref={isDesktop && i === 1 ? filtersRef : null || !isDesktop && i === 0 ? filtersRef : null}>
                  <WrapWorkImage>
                    {project.workFields.featuredInfos.imageOrVideo === 'image' && (
                      <>
                        {isDesktop === true && (
                          <MaskedImage
                            style={{ position: 'absolute' }}
                            fluid={
                              (i === 0 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 3 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 4 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 7 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 8 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 11 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 12 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 15 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 16 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 19 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 20 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 223 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 24 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 27 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 28 && project.workFields.featuredInfos.imagePortrait) ||
                              (i === 31 && project.workFields.featuredInfos.imagePortrait)
                                ? project.workFields.featuredInfos.imagePortrait.imageFile
                                    .childImageSharp.fluid
                                : project.workFields.featuredInfos.image.imageFile.childImageSharp.fluid
                            }
                            alt={project.workFields.featuredInfos.image.altText}
                            hover="true"
                          />
                        )}
                        {!isDesktop === true && (
                          <MaskedImage
                            style={{ position: 'absolute' }}
                            fluid={project.workFields.featuredInfos.image.imageFile.childImageSharp.fluid}
                            alt={project.workFields.featuredInfos.image.altText}
                            hover="true"
                          />
                        )}
                      </>
                    )}
                    {project.workFields.featuredInfos.imageOrVideo === 'video' && (
                      <>
                        {isDesktop === true && (
                          <MaskedVideo
                            video={
                              (i === 0 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 3 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 4 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 7 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 8 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 11 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 12 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 15 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 16 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 19 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 20 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 223 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 24 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 27 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 28 && project.workFields.featuredInfos.videoPortrait) ||
                              (i === 31 && project.workFields.featuredInfos.videoPortrait)
                                ? project.workFields.featuredInfos.videoPortrait.mediaItemUrl
                                : project.workFields.featuredInfos.videoSquare.mediaItemUrl
                            }
                            hover="true"
                          />
                        )}
                        {!isDesktop === true && (
                          <MaskedImage
                            style={{ position: 'absolute' }}
                            fluid={
                              project.workFields.featuredInfos.videoImageFallback.imageFile
                                .childImageSharp.fluid
                            }
                            alt={project.workFields.featuredInfos.image.altText}
                            hover="true"
                          />
                        )}
                      </>
                    )}
                  </WrapWorkImage>
                  <ItemDesc>
                    {project.categories.nodes.map((cat, i) => (
                      <span key={i}>{cat.name} </span>
                    ))}
                    <h2> {project.workFields.featuredInfos.title} </h2>
                    <p> {project.workFields.featuredInfos.subtitle} </p>
                  </ItemDesc>
                </WrapItem>
              </WorkItem>
            ))}
          </WorkFeatured>
        </WorkBlock>
      </Observer>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  transform: translateY(-10vh);

  @media (max-width: 1024px) {
    transform: translateY(0);
  }
`

const WorkBlock = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
`

const WrapFilters = styled(animated.div)`
  position: absolute;
  right: 9.75%;
  width: 38.75%;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  color: ${props => props.theme.colours.pink};
  z-index: 10;

  @media (max-width: 1180px) {
    transform: translateY(-50%);
  }
  
  @media (max-width: 1024px) {
    transform: translateY(0);
  }
  
  @media (max-width: 650px) {
    position: relative;
    margin: 40px 0 20px;
    justify-content: center;
    right: unset;
    width: 100%;
  }
  
`

const IndividualFilter = styled(animated.button)`
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  font-size: 16px;
  color: ${props => props.theme.colours.grey };
  border: 0;
  padding: 0;
  cursor: pointer;
  text-transform: capitalize;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    &::before {
      background-color: ${props => props.theme.colours.pink};
    }
  }

  &:focus {
    outline: none;
  }

  &::before {
    content: '';
    width: 18px;
    height: 18px;
    border: 1px solid #000;
    margin-right: 10px;
    background-color: #fff;
    transition: background-color 0.3s ease;
  }

  &:disabled {
    pointer-events: none;
    color: #cccccc;
    
    &::before {
      border-color: #E1DFDF;
      background-color: #ffffff;
    }
  }

  span {
    color: ${props => props.theme.colours.pink};
  }
`

const FilterInner = styled(animated.div)`
  position: absolute;
  width: 281px;
  top: 30px;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0px 0px 39px rgba(0, 0, 0, 0.16);
  transform: translateX(52px);
  z-index: 10;
  overflow: hidden;
  
  @media (max-width: 650px) {
    right: 50%;
    transform: translateX(50%);
  }
`

const Divider = styled.div`
  border-bottom: 1px solid rgb(122, 112, 112, 0.3);
  margin-top: 15px;
`

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 55px 35px 15px 35px;

  ${IndividualFilter} {
    margin-bottom: 10px;
    font-size: 22px;
  }
`

const FilterGroupIndustry = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 15px;
  padding: 0 35px 15px 35px;

  ${IndividualFilter} {
    &[value="all"] {
      margin-top: 15px;
    }
  }
`

const IndustryToggleArea = styled(animated.div)`
  display: flex;
  flex-direction: column;
`


const FilterGroupHeading = styled.p`
  display: flex;
  justify-content: space-between;
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  font-size: 16px;
  color: ${props => props.theme.colours.grey };
  margin-bottom: 0;
  cursor: pointer;

  svg path {
    fill: ${props => props.theme.colours.grey};
  }
`

const FilterButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 180px;
  background-color: ${props => props.theme.colours.pink};
  color: #fff;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 20px;
  border-radius: 50px;
  padding: 21px 32px 20px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 20px;
  z-index: 11;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 27px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
  margin: -12px 0 25px;
`

const ClearButton = styled.button`
  font-family: ${props => props.theme.fonts.circular};
  border: 2px solid #fff;
  color: #757575;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #fff;
  letter-spacing: 0.1px;
  border-radius: 50px;
  padding: 20px 25px;
  cursor: pointer;
  transition: color 0.3s ease;
  
  &:hover {
    color: ${props => props.theme.colours.pink};
  }
`

const CloseButton = styled.button`
  font-family: ${props => props.theme.fonts.circular};
  border: 2px solid ${props => props.theme.colours.pink};
  color: ${props => props.theme.colours.pink};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #fff;
  letter-spacing: 0.1px;
  border-radius: 50px;
  padding: 20px 25px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  
  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: #fff;
  }
`

const WorkFeatured = styled(animated.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 80%;
  width: 100%;
  min-height: 100vh;

  @media (max-width: 650px) {
    max-width: calc(100% - 50px);
  }
`

const WrapWorkImage = styled.div`
  position: relative;
`

const WrapItem = styled.div`
  width: 100%;
  padding: 0 0.5rem;

  @media (max-width: 650px) {
    margin-bottom: 6.5rem;
  }

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }
`

const WorkItem = styled(Link)`
  width: calc(50% - 2rem);

  @media (max-width: 769px) {
    width: 47%;
  }

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }

  ${WrapWorkImage} {
    ${aspectRatio(537, 469)}
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;

    &:nth-child(odd) {
      width: 100%;

      ${WrapWorkImage} {
        width: 100%;
        padding: 0;
        ${aspectRatio(537, 469)}
      }
    }

    &:nth-child(even) {
      width: 100%;

      ${WrapWorkImage} {
        ${aspectRatio(537, 469)}
        width: 100%;
        padding: 0;
      }
    }
  }

  @media (min-width: 650px) {
    &:nth-child(4n + 1) {
      width: 42%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 650px) {
        width: 100%;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }

    &:nth-child(4n) {
      width: 42%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 650px) {
        width: 100%;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }
  }

  @media (min-width: 700px) and (max-width: 1024px) {
    &:nth-child(4n + 1) {
      width: 47%;
      padding: 0;
      margin-bottom: 5rem;

      @media (max-width: 769px) {
        width: 47%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }

    &:nth-child(4n) {
      width: 47%;
      padding: 0;
      margin-bottom: 5rem;

      @media (max-width: 769px) {
        width: 47%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }
  }
`

const ItemDesc = styled.div`
  margin-top: 2.5rem;
  span {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 0.22rem;
    text-transform: uppercase;
    margin-right: 1.5rem;
    display: inline-block;
    line-height: 1.75;

    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 1.75rem;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 4.5rem;
    letter-spacing: -0.075rem;
    margin-top: 1rem;
    transition: color 0.25s ease;

    @media (max-width: 650px) {
      margin-top: 2rem;
      font-size: 5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;

    @media (max-width: 1024px) {
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 2.2rem;
    }
  }
`

export default WorkList
